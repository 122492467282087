<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div id="all_wrap">
    <main>
      <div class="mv">
        <div class="mv_cloud">
          <div class="right_area">
            <div class="img04">
              <img
                src="@/assets/front_component/images/assets/mv04.png"
                alt="雲"
              />
            </div>
            <div class="img05">
              <img
                src="@/assets/front_component/images/assets/mv05.png"
                alt="雲"
              />
            </div>
            <div class="img06">
              <img
                src="@/assets/front_component/images/assets/mv06.png"
                alt="雲"
              />
            </div>
          </div>
          <div class="left_area">
            <div class="img01">
              <img
                src="@/assets/front_component/images/assets/mv01.png"
                alt="雲"
              />
            </div>
            <div class="img02">
              <img
                src="@/assets/front_component/images/assets/mv02.png"
                alt="雲"
              />
            </div>
            <div class="img03">
              <img
                src="@/assets/front_component/images/assets/mv03.png"
                alt="雲"
              />
            </div>
          </div>
        </div>
        <div
          class="mv_content"
          style="position: relative; z-index: 10"
          v-if="phone"
        >
          <div class="profile_wrap">
            <div class="user_wrap">
              <div class="left_area">
                <router-link
                  :to="{ name: 'Front user edit' }"
                  style="position: relative"
                >
                  <div
                    class="user_img_wrap"
                    :style="{ backgroundImage: 'url(' + profileImg + ')' }"
                    v-if="profileImg"
                  ></div>
                  <div
                    class="user_img_wrap"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/front_component/images/test/test01.png') +
                        ')',
                    }"
                    v-else
                  ></div>
                </router-link>
              </div>
              <div class="right_area">
                <div class="user_name">
                  {{ $User.last_name }} {{ $User.first_name }}
                </div>
                <div
                  class="director_name"
                  v-if="$User.group && $User.group.parent_name"
                >
                  {{ $User.group.parent_name }}
                </div>
                <div class="director_name" v-else-if="$User.group">
                  {{ $User.group.name }}
                </div>
              </div>
            </div>
            <ul class="awards_link">
              <li class="awards_item" v-for="num of awardTenNum" :key="num">
                <img
                  src="@/assets/front_component/images/assets/awards01.png"
                  alt="10個"
                />
              </li>
              <li class="awards_item" v-for="num of awardOneNum" :key="num">
                <img
                  src="@/assets/front_component/images/assets/awards02.png"
                  alt="1個"
                />
              </li>
            </ul>
            <div class="reaction_wrap">
              <div class="reaction_item">
                <span>サンクス/ホメロン </span
                ><router-link
                  :to="{ hash: '#target_area', name: 'Front user edit' }"
                  >{{ thanksNum }}</router-link
                >
              </div>
            </div>
            <div>
                <router-link
                to="/mypage/user#thanks-heading"
                v-if="notifiationNumForThanks > 0"
                class="notification_num_thanks"
                >サンクス未読：<span>{{ notifiationNumForThanks }}</span
                >件</router-link
              >
            </div>
            <div style="margin-top: 8px;">
              <router-link
                  :to="{ name: 'Front approval' }"
                  class="notification_num"
                  v-if="approvalNum > 0"
              >承認依頼：<span
              >{{ approvalNum }}</span
              >件</router-link
              >
            </div>
          </div>
        </div>
        <div class="mv_content" v-else>
          <div class="left_area">
            <div class="logo_wrap">
              <img
                src="@/assets/front_component/images/logo/logo02.png"
                alt="TAKAYAMA"
              />
            </div>
          </div>
          <div class="right_area">
            <div class="profile_wrap">
              <div class="left_area">
                <router-link to="/mypage/user">
                  <div
                    class="user_wrap"
                    :style="{ backgroundImage: 'url(' + profileImg + ')' }"
                    v-if="profileImg"
                  ></div>
                  <div
                    class="user_wrap"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/front_component/images/test/test01.png') +
                        ')',
                    }"
                    v-else
                  ></div>
                </router-link>
              </div>
              <div class="right_area">
                <div class="user_name">
                  {{ $User.last_name }} {{ $User.first_name }}
                </div>
                <div
                  class="director_name"
                  v-if="$User.group && $User.group.parent_name"
                >
                  {{ $User.group.parent_name }}
                </div>
                <div class="director_name" v-else-if="$User.group">
                  {{ $User.group.name }}
                </div>
                <ul class="awards_link">
                  <li class="awards_item" v-for="num of awardTenNum" :key="num">
                    <img
                      src="@/assets/front_component/images/assets/awards01.png"
                      alt="10個"
                    />
                  </li>
                  <li class="awards_item" v-for="num of awardOneNum" :key="num">
                    <img
                      src="@/assets/front_component/images/assets/awards02.png"
                      alt="1個"
                    />
                  </li>
                </ul>
                <div class="reaction_wrap">
                  <div class="reaction_item">
                    <span>サンクス/ホメロン</span
                    ><router-link
                      :to="{ hash: '#target_area', name: 'Front user edit' }"
                      >{{ thanksNum }}</router-link
                    >
                  </div>
                </div>
                <div>
                  <router-link
                    to="/mypage/user#thanks-heading"
                    v-if="notifiationNumForThanks > 0"
                    class="notification_num_thanks"
                    >サンクス未読：<span>{{ notifiationNumForThanks }}</span
                  >件</router-link
                  >
                </div>
                <div style="margin-top: 8px;">
                  <router-link
                      :to="{ name: 'Front approval' }"
                      class="notification_num"
                      v-if="approvalNum > 0"
                  >承認依頼：<span
                  >{{ approvalNum }}</span
                  >件</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mascot_wrap">
          <img
            src="@/assets/front_component/images/assets/meguru01.png"
            alt="めぐる君"
            class="first"
          />
          <img
            src="@/assets/front_component/images/assets/meguru01_end.png"
            alt="めぐる君"
            class="end"
          />
          <template v-if="!phone">
            <div class="eye lefteye"><img src="@/assets/front_component/images/assets/meguru06.png" alt="目"></div>
            <div class="eye righteye"><img src="@/assets/front_component/images/assets/meguru07.png" alt="目"></div>
          </template>
        </div>
      </div>
      <div class="section_all_wrap">
        <div v-if="show_megurukun_animation">
          <TopAnimation/>
        </div>
        <div class="megurukun_animation-loader" v-else>
          <div class="mt-100 mb-100" >
            <div class="half-circle-spinner white">
              <div class="circle circle-1"></div>
              <div class="circle circle-2"></div>
            </div>
          </div>
        </div>  
        <section class="topics_section">
          <div class="content_wrap">
            <h2 class="ttl_01 side">
              <span class="ja">トピックス</span><span class="en">Topics</span>
            </h2>
            <div class="topics_btn_wrap">
              <div class="arrow_wrap">
                <div class="slide_prev arrow slick-arrow">
                  <div class="img_wrap">
                    <img
                      src="@/assets/front_component/images/assets/arrow01.png"
                      alt="戻る"
                    />
                  </div>
                </div>
                <div class="slide_next arrow slick-arrow">
                  <div class="img_wrap">
                    <img
                      src="@/assets/front_component/images/assets/arrow02.png"
                      alt="進む"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="topics_slide topics_list"
            v-if="topics_messages.length != 0"
            style="opacity: 0; transition: 0.5s"
          >
            <div
              class="topisc_item"
              v-for="message in topics_messages"
              :key="message.id"
            >
              <router-link
                v-if="
                  message.type == 'column' &&
                  message.from_user.role == '代表取締役社長'
                "
                :to="{
                  name: 'Front show',
                  params: { type: message.type, id: message.id },
                }"
                class=""
              >
                <div
                  class="img_wrap"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/front_component/images/thum/ceo.jpg') +
                      ')',
                  }"
                  v-if="message.eyecatch_base64 == null"
                ></div>
                <div
                  class="img_wrap"
                  :style="{
                    backgroundImage:
                      'url(data:image/jpeg;base64,' +
                      message.eyecatch_base64 +
                      ')',
                  }"
                  v-else
                ></div>
                <div class="txt_wrap">
                  <div class="info_wrap">
                    <div class="cat_name" :class="message.type">コラム</div>
                    <p class="date">
                      <time
                        class="en"
                        :datetime="formatDate_time(message.published_at)"
                        >{{ formatDate(message.published_at) }}</time
                      >
                    </p>
                  </div>
                  <h3 class="list_header">{{ message.title }}</h3>
                  <p class="post_name" v-if="message.from_user">
                    投稿者：<span>
                      {{
                        message.type == "award"
                          ? "めぐる君"
                          : message.from_user.last_name +
                            " " +
                            message.from_user.first_name
                      }}
                    </span>

                    <span class="column-role">社長</span>
                  </p>
                </div>
              </router-link>
              <router-link
                v-else-if="
                  message.type == 'column' &&
                  message.from_user.role == '取締役会長'
                "
                :to="{
                  name: 'Front show',
                  params: { type: message.type, id: message.id },
                }"
                class=""
              >
                <div
                  class="img_wrap"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/front_component/images/thum/ceo.jpg') +
                      ')',
                  }"
                  v-if="message.eyecatch_base64 == null"
                ></div>
                <div
                  class="img_wrap"
                  :style="{
                    backgroundImage:
                      'url(data:image/jpeg;base64,' +
                      message.eyecatch_base64 +
                      ')',
                  }"
                  v-else
                ></div>
                <div class="txt_wrap">
                  <div class="info_wrap">
                    <div class="cat_name" :class="message.type">コラム</div>
                    <p class="date">
                      <time
                        class="en"
                        :datetime="formatDate_time(message.published_at)"
                        >{{ formatDate(message.published_at) }}</time
                      >
                    </p>
                  </div>
                  <h3 class="list_header">{{ message.title }}</h3>
                  <p class="post_name" v-if="message.from_user">
                    投稿者：<span>
                      {{
                        message.type == "award"
                          ? "めぐる君"
                          : message.from_user.last_name +
                            " " +
                            message.from_user.first_name
                      }}</span
                    >

                    <span class="column-role">会長</span>
                  </p>
                </div>
              </router-link>

              <router-link
                v-else-if="message.type == 'event'"
                :to="{
                  name: 'Front event show',
                  params: { type: message.type, id: message.id },
                }"
              >
                <div
                  class="img_wrap"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/front_component/images/thum/' +
                        message.type +
                        '.jpg') +
                      ')',
                  }"
                  v-if="message.eyecatch_base64 == null"
                ></div>
                <div
                  class="img_wrap"
                  :style="{
                    backgroundImage:
                      'url(data:image/jpeg;base64,' +
                      message.eyecatch_base64 +
                      ')',
                  }"
                  v-else
                ></div>
                <div class="txt_wrap">
                  <div class="info_wrap" :class="message.type">
                    <div
                      class="cat_name"
                      :class="message.type"
                      v-if="message.type == 'event'"
                    >
                      イベント
                    </div>
                    <p class="date">
                      <time
                        class="en"
                        :datetime="formatDate_time(message.published_at)"
                        >{{ formatDate(message.published_at) }}</time
                      >
                    </p>
                  </div>
                  <h3 class="list_header">{{ message.title }}</h3>
                  <p class="post_name" v-if="message.from_user">
                    投稿者：<span
                      >{{ message.from_user.last_name }}
                      {{ message.from_user.first_name }}</span
                    >
                  </p>
                </div>
              </router-link>
              <router-link
                v-else
                :to="{
                  name: 'Front show',
                  params: { type: message.type, id: message.id },
                }"
              >
                <div
                  class="img_wrap"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/front_component/images/thum/' +
                        message.type +
                        '.jpg') +
                      ')',
                  }"
                  v-if="message.eyecatch_base64 == null"
                ></div>
                <div
                  class="img_wrap"
                  :style="{
                    backgroundImage:
                      'url(data:image/jpeg;base64,' +
                      message.eyecatch_base64 +
                      ')',
                  }"
                  v-else
                ></div>
                <div class="txt_wrap">
                  <div class="info_wrap" :class="message.type">
                    <div
                      class="cat_name"
                      :class="message.type"
                      v-if="message.type == 'news'"
                    >
                      お知らせ
                    </div>
                    <div
                      class="cat_name"
                      :class="message.type"
                      v-else-if="message.type == 'award'"
                    >
                      表彰
                    </div>
                    <div
                      class="cat_name"
                      :class="message.type"
                      v-else-if="message.type == 'column'"
                    >
                      コラム
                    </div>
                    <div
                      class="cat_name"
                      :class="message.type"
                      v-else-if="message.type == 'event'"
                    >
                      イベント
                    </div>
                    <p class="date">
                      <time
                        class="en"
                        :datetime="formatDate_time(message.published_at)"
                        >{{ formatDate(message.published_at) }}</time
                      >
                    </p>
                  </div>
                  <h3 class="list_header">{{ message.title }}</h3>
                  <p class="post_name" v-if="message.from_user">
                    投稿者：<span
                      >{{
                        message.type == "award"
                          ? "めぐる君"
                          : message.from_user.last_name +
                            " " +
                            message.from_user.first_name
                      }}
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
          </div>
          <div class="topics_slide topics_list" v-else>
            <div class="p_t100">
              <div class="half-circle-spinner white">
                <div class="circle circle-1"></div>
                <div class="circle circle-2"></div>
              </div>
            </div>
          </div>
        </section>
        <div class="section_wrap">
          <div class="content_wrap">
            <div class="content_in_wrap">
              <section class="news_section">
                <div class="news_wrap">
                  <h2 class="ttl_01 bottom">
                    <span class="ja">お知らせ</span><span class="en">News</span>
                  </h2>
                  <ul class="news_list" v-if="news_messages.length != 0">
                    <li
                      v-for="message in news_messages.slice(0, 5)"
                      :key="message.id"
                    >
                      <router-link
                        :to="{
                          name: 'Front show',
                          params: { type: 'news', id: message.id },
                        }"
                      >
                        <time
                          class="en"
                          :datetime="formatDate_time(message.published_at)"
                          >{{ formatDate(message.published_at) }}</time
                        >
                        <h3 class="txt">{{ message.title }}</h3>
                      </router-link>
                    </li>
                  </ul>
                  <ul class="news_list" v-else>
                    <div class="p_t100">
                      <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                      </div>
                    </div>
                  </ul>
                  <div class="more" v-if="news_messages.length != 0">
                    <div class="btn_norm">
                      <router-link
                        class="en"
                        :to="{
                          name: 'Front archive',
                          params: { type: 'news' },
                        }"
                        >more</router-link
                      >
                    </div>
                  </div>
                  <div class="deco_wrap01 animation">
                    <img
                      src="@/assets/front_component/images/assets/meguru03.png"
                      alt="めぐる君"
                    />
                  </div>
                </div>
              </section>
              <section class="dairy_section">
                <div class="dairy_wrap">
                  <h2 class="ttl_01 bottom">
                    <span class="ja">コラム</span><span class="en">Column</span>
                  </h2>
                  <ul class="news_list" v-if="column_messages.length != 0">
                    <li v-for="message in column_messages" :key="message.id">
                      <router-link
                        :to="{
                          name: 'Front show',
                          params: { type: 'column', id: message.id },
                        }"
                        v-if="
                          message.from_user.group
                            ? message.from_user.group.group_role ==
                              '代表取締役社長'
                            : false
                        "
                        class=""
                      >
                        <time
                          class="en"
                          :datetime="formatDate_time(message.published_at)"
                          >{{ formatDate(message.published_at) }}</time
                        >
                        <small class="cat_name syatyou">社長 </small>
                        <h3 class="txt" v-html="message.title"></h3>
                      </router-link>
                      <router-link
                        :to="{
                          name: 'Front show',
                          params: { type: 'column', id: message.id },
                        }"
                        v-else-if="
                          message.from_user.group
                            ? message.from_user.group.group_role == '取締役会長'
                            : false
                        "
                        class="kaityo"
                      >
                        <time
                          class="en"
                          :datetime="formatDate_time(message.published_at)"
                          >{{ formatDate(message.published_at) }}</time
                        >
                        <small class="cat_name kaityou">会長 </small>
                        <h3 class="txt" v-html="message.title"></h3>
                      </router-link>
                      <router-link
                        :to="{
                          name: 'Front show',
                          params: { type: 'column', id: message.id },
                        }"
                        v-else-if="
                          message.from_user.group
                            ? message.from_user.group.id == '52'
                            : false
                        "
                        class="yakuin"
                      >
                        <time
                          class="en"
                          :datetime="formatDate_time(message.published_at)"
                          >{{ formatDate(message.published_at) }}</time
                        >
                        <small class="cat_name yakuin">役員 </small>
                        <h3 class="txt" v-html="message.title"></h3>
                      </router-link>
                      <router-link
                        :to="{
                          name: 'Front show',
                          params: { type: 'column', id: message.id },
                        }"
                        v-else
                      >
                        <time
                          class="en"
                          :datetime="formatDate_time(message.published_at)"
                          >{{ formatDate(message.published_at) }}</time
                        >
                        <small class="cat_name syain">社員 </small>
                        <h3 class="txt" v-html="message.title"></h3>
                      </router-link>
                    </li>
                  </ul>
                  <ul class="news_list" v-else>
                    <div class="p_t100">
                      <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                      </div>
                    </div>
                  </ul>
                  <div class="more" v-if="column_messages.length != 0">
                    <div class="btn_norm">
                      <router-link
                        class="en"
                        :to="{
                          name: 'Front archive',
                          params: { type: 'column' },
                        }"
                        >more</router-link
                      >
                    </div>
                  </div>
                  <div class="deco_wrap01">
                    <img
                      src="@/assets/front_component/images/assets/kikyu01.png"
                      alt="気球"
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <section class="photo_section">
          <div class="content_wrap">
            <div class="content_in_wrap">
              <h2 class="ttl_01 side">
                <span class="ja">フォトログ</span
                ><span class="en">Photolog</span>
              </h2>
              <div class="photo_wrap">
                <ul id="gallery_list" v-if="photo_messages.length != 0">
                  <li
                    class="gallery_wrap"
                    v-for="(message, index) in photo_messages"
                    :key="message.id"
                  >
                    <a
                      class="pop-modal gallery_show"
                      :href="'#inline-wrap0' + index"
                    >
                      <div
                        class="img_wrap"
                        v-bind:style="{
                          backgroundImage:
                            'url(data:image/jpeg;base64,' +
                            message.photo_base64 +
                            ')',
                        }"
                      >
                        <div class="hover_text">
                          <div class="txt_wrap">
                            <h3 class="gallery_ttl">{{ message.content }}</h3>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div
                      :id="'inline-wrap0' + index"
                      class="mfp-hide inline-wrap"
                      data-group="1"
                    >
                      <div class="img_list">
                        <div class="img_wrap">
                          <img
                            :src="
                              'data:image/jpeg;base64,' + message.photo_base64
                            "
                          />
                        </div>
                      </div>
                      <div class="txt_wrap photo">
                        <p v-if="message.from_user" class="name">
                          <span v-html="message.deparment"></span>
                          <span v-html="message.from_user.last_name"></span
                          ><span v-html="message.from_user.first_name"></span>
                        </p>
                        <h3>{{ message.content }}</h3>
                      </div>
                      <button
                        title="Close (Esc)"
                        type="button"
                        class="mfp-close"
                      >
                        ×
                      </button>
                    </div>
                  </li>
                </ul>
                <div v-else class="p_t100">
                  <div class="half-circle-spinner white">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                  </div>
                </div>
                <div class="deco_wrap01">
                  <img
                    src="@/assets/front_component/images/assets/bard03.png"
                    alt="白鳥"
                  />
                </div>
                <div class="deco_wrap02">
                  <img
                    src="@/assets/front_component/images/assets/hana01.png"
                    alt="花"
                    class="hana01"
                  />
                  <img
                    src="@/assets/front_component/images/assets/hana02.png"
                    alt="花"
                    class="hana02"
                  />
                  <img
                    src="@/assets/front_component/images/assets/hana03.png"
                    alt="花"
                    class="hana03"
                  />
                </div>
              </div>
              <div class="more">
                <div class="btn_norm">
                  <router-link class="en" :to="{ name: 'Front photolog' }"
                    >more</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="board_section">
          <div class="content_wrap">
            <div class="content_in_wrap">
              <h2 class="ttl_01 bottom">
                <span class="ja">掲示板</span
                ><span class="en">Message Board</span>
              </h2>
            </div>
            <div class="board_wrap">
              <div class="content_in_wrap">
                <ul class="board_list" v-if="board_messages.length != 0">
                  <li
                    v-for="message in board_messages.slice(0, 8)"
                    :key="message.id"
                  >
                    <router-link
                      :to="{
                        name: 'Front board show',
                        params: { type: 'board', id: message.id },
                      }"
                    >
                      <h3 class="board_ttl" style="word-wrap: break-word">
                        {{ message.title }}
                      </h3>
                      <div class="txt_wrap">
                        <div class="board_time">
                          <img
                            src="@/assets/front_component/images/assets/board01.png"
                            alt="時間"
                          />
                          <time
                            class="en"
                            :datetime="formatDate_time(message.published_at)"
                            >{{ formatDate(message.published_at) }}</time
                          >
                        </div>
                        <div class="board_com">
                          <img
                            src="@/assets/front_component/images/assets/board02.png"
                            alt="コメント"
                          /><span class="en">{{ message.child_num }}</span>
                        </div>
                        <div class="board_good">
                          <img
                            src="@/assets/front_component/images/assets/board03.png"
                            alt="お気に入り"
                          /><span class="en">{{ message.good_num }}</span>
                        </div>
                      </div>
                      <img
                        src="@/assets/front_component/images/assets/board04.png"
                        alt="角"
                        class="dog"
                      />
                    </router-link>
                  </li>
                </ul>
                <ul class="board_list" v-else>
                  <div class="p_t100">
                    <div class="half-circle-spinner white">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div>
                </ul>
                <div class="more" v-if="board_messages.length != 0">
                  <div class="btn_norm">
                    <router-link
                      class="en"
                      :to="{
                        name: 'Front board archive',
                        params: { type: 'board' },
                      }"
                      >more</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="deco_wrap01 animation">
                <img
                  src="@/assets/front_component/images/assets/meguru04.png"
                  alt="めぐる君"
                />
              </div>
              <div class="deco_wrap02">
                <img
                  src="@/assets/front_component/images/assets/bard01.png"
                  alt="青鳥"
                />
              </div>
              <div class="deco_wrap03">
                <img
                  src="@/assets/front_component/images/assets/bard02.png"
                  alt="緑鳥"
                />
              </div>
              <div class="deco_wrap04">
                <img
                  src="@/assets/front_component/images/assets/heri01.png"
                  alt="ヘリコプター"
                />
              </div>
              <div class="deco_wrap05">
                <img
                  src="@/assets/front_component/images/assets/hana01.png"
                  alt="花"
                  class="hana01"
                />
                <img
                  src="@/assets/front_component/images/assets/hana02.png"
                  alt="花"
                  class="hana02"
                />
                <img
                  src="@/assets/front_component/images/assets/hana03.png"
                  alt="花"
                  class="hana03"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="tab_section">
          <div class="content_wrap">
            <div class="content_in_wrap">
              <div class="tab_wrap">
                <ul class="tab_list" style="justify-content: center !important;">
                  <li class="all active">
                    <div class="icon_wrap">
                      <img
                        src="@/assets/front_component/images/assets/tab01_out.png"
                        alt="すべて表示"
                        class="mouse_out"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab01_over.png"
                        alt="すべて表示"
                        class="mouse_over"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab01_active.png"
                        alt="すべて表示"
                        class="mouse_active"
                      />
                    </div>
                    <h3>すべて表示</h3>
                  </li>
                  <li class="event">
                    <div class="icon_wrap">
                      <img
                        src="@/assets/front_component/images/assets/tab02_out.png"
                        alt="イベント"
                        class="mouse_out"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab02_over.png"
                        alt="イベント"
                        class="mouse_over"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab02_active.png"
                        alt="イベント"
                        class="mouse_active"
                      />
                    </div>
                    <h3>イベント</h3>
                  </li>
                  <li class="awards">
                    <div class="icon_wrap">
                      <img
                        src="@/assets/front_component/images/assets/tab03_out.png"
                        alt="表彰"
                        class="mouse_out"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab03_over.png"
                        alt="表彰"
                        class="mouse_over"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab03_active.png"
                        alt="表彰"
                        class="mouse_active"
                      />
                    </div>
                    <h3>表彰</h3>
                  </li>
                  <!--
                  <li class="thanks">
                    <div class="icon_wrap">
                      <img src="@/assets/front_component/images/assets/tab04_out.png" alt="サンクスカード" class="mouse_out">
                      <img src="@/assets/front_component/images/assets/tab04_over.png" alt="サンクスカード" class="mouse_over">
                      <img src="@/assets/front_component/images/assets/tab04_active.png" alt="サンクスカード" class="mouse_active">
                    </div>
                    <h3>サンクスカード</h3>
                  </li>
                  -->
                  <!-- <li class="yaruki" style="display:none">
                    <div class="icon_wrap">
                      <img
                        src="@/assets/front_component/images/assets/tab05_out.png"
                        alt="チャレンジシート"
                        class="mouse_out"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab05_over.png"
                        alt="チャレンジシート"
                        class="mouse_over"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab05_active.png"
                        alt="チャレンジシート"
                        class="mouse_active"
                      />
                    </div>
                    <h3>チャレンジシート</h3>
                  </li> -->
                  <li class="idea">
                    <div class="icon_wrap">
                      <img
                        src="@/assets/front_component/images/assets/tab06_out.png"
                        alt="創発"
                        class="mouse_out"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab06_over.png"
                        alt="創発"
                        class="mouse_over"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab06_active.png"
                        alt="創発"
                        class="mouse_active"
                      />
                    </div>
                    <h3>創発</h3>
                  </li>
                  <li class="daily" >
                    <div class="icon_wrap">
                      <img
                        src="@/assets/front_component/images/assets/tab10_out.png"
                        alt="気づき日報"
                        class="mouse_out"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab10_over.png"
                        alt="気づき日報"
                        class="mouse_over"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab10_active.png"
                        alt="気づき日報"
                        class="mouse_active"
                      />
                    </div>
                    <h3>気づき日報</h3>
                  </li>
                  <!-- <li class="sdgs" style="display:none">
                    <div class="icon_wrap">
                      <img
                        src="@/assets/front_component/images/assets/tab08_out.png"
                        alt="SDGs個人目標"
                        class="mouse_out"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab08_over.png"
                        alt="SDGs個人目標"
                        class="mouse_over"
                      />
                      <img
                        src="@/assets/front_component/images/assets/tab08_active.png"
                        alt="SDGs個人目標"
                        class="mouse_active"
                      />
                    </div>
                    <h3>SDGs個人目標</h3>
                  </li> -->
                </ul>
                <div class="deco_wrap01">
                  <img
                    src="@/assets/front_component/images/assets/kikyu02.png"
                    alt="気球"
                  />
                </div>
              </div>
              <div class="link_wrap">
                <ul
                  class="link_list all active"
                  v-if="all_messages.length != 0"
                >
                  <li
                    v-for="message in all_messages.slice(0, 6)"
                    :key="message.id"
                  >
                    <router-link
                      v-if="message.type == 'event'"
                      :to="{
                        name: 'Front event show',
                        params: { type: 'event', id: message.id },
                      }"
                    >
                      <span class="cat_name" :class="message.type"
                        >イベント</span
                      >
                      <h4 class="link_ttl">{{ message.title }}</h4>
                    </router-link>

                    <router-link
                      v-else
                      :to="{
                        name: 'Front show',
                        params: { type: message.type, id: message.id },
                      }"
                    >
                      <span class="cat_name" :class="message.type">{{
                        message.typejp
                      }}</span>
                      <h4
                        v-if="
                          message.type == 'thanks' || message.type == 'idea'
                        "
                        class="link_ttl"
                      >
                        {{ message.content }}
                      </h4>

                      <h4
                        v-else-if="
                          message.type == 'challenge' || message.type == 'sdgs'
                        "
                        class="link_ttl"
                      >
                        <small
                          v-if="message.user"
                          style="
                            display: inline-block;
                            background: #666;
                            color: #fff;
                            padding: 0.1em 0.5em;
                            border-radius: 5px;
                            margin-right: 0.5em;
                          "
                        >
                          {{ message.user ? message.user.last_name : "" }}
                          {{ message.user ? message.user.first_name : "" }}
                        </small>

                        <img
                          v-if="!message.user"
                          src="https://www.kdfc.com/wp-content/themes/uscradio/dist/images/spinner-1.gif"
                          width="25"
                          height="25"
                          style="margin-right: 10px"
                        />

                        {{ showYear(message.option) }}
                      </h4>

                      <h4 v-else-if="message.type == 'daily'" class="link_ttl">
                        <small
                          v-for="daily in message.tags"
                          :key="daily.id"
                          style="
                            display: inline-block;
                            background: #666;
                            color: #fff;
                            padding: 0.1em 0.5em;
                            border-radius: 5px;
                            margin-right: 0.5em;
                          "
                        >
                          {{ daily.name }}</small
                        >

                        {{ message.content }}
                      </h4>
                      <h4 v-else class="link_ttl">{{ message.title }}</h4>
                    </router-link>
                  </li>
                </ul>
                <ul class="link_list all active" v-else>
                  <div class="p_t100">
                    <div class="half-circle-spinner white">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div>
                </ul>

                <ul class="link_list event" v-if="event_messages.length != 0">
                  <li
                    v-for="message in event_messages.slice(0, 6)"
                    :key="message.id"
                  >
                    <router-link
                      :to="{
                        name: 'Front event show',
                        params: { type: 'event', id: message.id },
                      }"
                    >
                      <span class="cat_name" :class="message.type"
                        >イベント</span
                      >
                      <h4 class="link_ttl">{{ message.title }}</h4>
                    </router-link>
                  </li>
                </ul>
                <ul class="link_list event" v-else>
                  <div class="p_t100">
                    <div class="half-circle-spinner white">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div>
                </ul>
                <div class="more event">
                  <div class="btn_norm">
                    <router-link
                      class="en"
                      :to="{
                        name: 'Front event archive',
                        params: { type: 'event' },
                      }"
                      >more</router-link
                    >
                  </div>
                </div>

                <ul class="link_list awards" v-if="award_messages.length != 0">
                  <li
                    v-for="message in award_messages.slice(0, 6)"
                    :key="message.id"
                  >
                    <router-link
                      :to="{
                        name: 'Front show',
                        params: { type: message.type, id: message.id },
                      }"
                    >
                      <span class="cat_name" :class="message.type">表彰</span>
                      <h4 class="link_ttl">{{ message.title }}</h4>
                    </router-link>
                  </li>
                </ul>
                <ul class="link_list awards" v-else>
                  <div class="p_t100">
                    <div class="half-circle-spinner white">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div>
                </ul>
                <div class="more awards">
                  <div class="btn_norm">
                    <router-link
                      class="en"
                      :to="{ name: 'Front archive', params: { type: 'award' } }"
                      >more</router-link
                    >
                  </div>
                </div>

                <!--
                <ul class="link_list thanks" v-if="thanks_messages.length != 0">
                  <li v-for="message in thanks_messages.slice(0, 6)" :key="message.id">
                    <router-link :to="{ name: 'Front show', params: { type: (message.type),id: (message.id) }}">
                      <span class="cat_name" :class="message.type">サンクスカード</span>
                      <h4 class="link_ttl">{{ message.content }}</h4>
                    </router-link>
                  </li>
                </ul>
                <div class="more thanks">
                  <div class="btn_norm">
                    <router-link class="en" :to="{ name: 'Front archive', params: { type: 'thanks' }}">more</router-link>
                  </div>
                </div>
              -->

                <ul
                  class="link_list yaruki"
                  v-if="challenge_messages.length != 0"
                >
                  <li
                    v-for="message in challenge_messages.slice(0, 6)"
                    :key="message.id"
                  >
                    <router-link
                      :to="{
                        name: 'Front show',
                        params: { type: message.type, id: message.id },
                      }"
                    >
                      <span class="cat_name" :class="message.type"
                        >チャレンジシート</span
                      >
                      <h4 class="link_ttl">
                        <img
                          v-if="!message.user"
                          src="https://www.kdfc.com/wp-content/themes/uscradio/dist/images/spinner-1.gif"
                          width="25"
                          height="25"
                          style="margin-right: 10px"
                        />
                        <small
                          v-else
                          style="
                            display: inline-block;
                            background: #666;
                            color: #fff;
                            padding: 0.1em 0.5em;
                            border-radius: 5px;
                            margin-right: 0.5em;
                          "
                        >
                          {{ message.user ? message.user.last_name : "" }}
                          {{ message.user ? message.user.first_name : "" }}
                        </small>

                        {{ showYear(message.option) }}
                      </h4>
                    </router-link>
                  </li>
                </ul>
                <ul class="link_list yaruki" v-else>
                  <div class="p_t100">
                    <div class="half-circle-spinner white">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div>
                </ul>
                <div class="more yaruki">
                  <div class="btn_norm">
                    <router-link
                      class="en"
                      :to="{
                        name: 'Front archive',
                        params: { type: 'challenge' },
                      }"
                      >more</router-link
                    >
                  </div>
                </div>

                <ul class="link_list idea" v-if="idea_messages.length != 0">
                  <li
                    v-for="message in idea_messages.slice(0, 6)"
                    :key="message.id"
                  >
                    <router-link
                      :to="{
                        name: 'Front show',
                        params: { type: message.type, id: message.id },
                      }"
                    >
                      <span class="cat_name" :class="message.type"
                        >創発</span
                      >
                      <h4 class="link_ttl">{{ message.content }}</h4>
                    </router-link>
                  </li>
                </ul>
                <ul class="link_list idea" v-else>
                  <div class="p_t100">
                    <div class="half-circle-spinner white">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div>
                </ul>
                <div class="more idea">
                  <div class="btn_norm">
                    <router-link
                      class="en"
                      :to="{ name: 'Front archive', params: { type: 'idea' } }"
                      >more</router-link
                    >
                  </div>
                </div>

                <ul class="link_list daily" v-if="daily_messages.length != 0">
                  <li
                    v-for="message in daily_messages.slice(0, 6)"
                    :key="message.id"
                  >
                    <router-link
                      :to="{
                        name: 'Front show',
                        params: { type: message.type, id: message.id },
                      }"
                    >
                      <span class="cat_name" :class="message.type"
                        >気づき日報</span
                      >
                      <h4 class="link_ttl">
                        <small
                          v-for="daily in message.tags"
                          :key="daily.id"
                          style="
                            display: inline-block;
                            background: #666;
                            color: #fff;
                            padding: 0.1em 0.5em;
                            border-radius: 5px;
                            margin-right: 0.5em;
                          "
                        >
                          {{ daily.name }}</small
                        >

                        {{ message.content }}
                      </h4>
                    </router-link>
                  </li>
                </ul>
                <ul class="link_list daily" v-else>
                  <div class="p_t100">
                    <div class="half-circle-spinner white">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div>
                </ul>
                <div class="more daily">
                  <div class="btn_norm">
                    <router-link
                      class="en"
                      :to="{ name: 'Front archive', params: { type: 'daily' } }"
                      >more</router-link
                    >
                  </div>
                </div>

                <ul class="link_list sdgs" v-if="sdgs_messages.length != 0">
                  <li
                    v-for="message in sdgs_messages.slice(0, 6)"
                    :key="message.id"
                  >
                    <router-link
                      :to="{
                        name: 'Front show',
                        params: { type: message.type, id: message.id },
                      }"
                    >
                      <span class="cat_name" :class="message.type">SDGs</span>
                      <h4 class="link_ttl">
                        <img
                          v-if="!message.user"
                          src="https://www.kdfc.com/wp-content/themes/uscradio/dist/images/spinner-1.gif"
                          width="25"
                          height="25"
                          style="margin-right: 10px"
                        />

                        <small
                          v-else
                          style="
                            display: inline-block;
                            background: #666;
                            color: #fff;
                            padding: 0.1em 0.5em;
                            border-radius: 5px;
                            margin-right: 0.5em;
                          "
                        >
                          {{ message.user ? message.user.last_name : "" }}
                          {{ message.user ? message.user.first_name : "" }}
                        </small>

                        {{ showYear(message.option) }}
                      </h4>
                    </router-link>
                  </li>
                </ul>
                <ul class="link_list sdgs" v-else>
                  <div class="p_t100">
                    <div class="half-circle-spinner white">
                      <div class="circle circle-1"></div>
                      <div class="circle circle-2"></div>
                    </div>
                  </div>
                </ul>
                <div class="more sdgs">
                  <div class="btn_norm">
                    <router-link
                      class="en"
                      :to="{ name: 'Front archive', params: { type: 'sdgs' } }"
                      >more</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="calendar_section">
          <div class="content_wrap">
            <div class="content_in_wrap">
              <div class="head_wrap">
                <h2 class="ttl_01 side">
                  <span class="ja">カレンダー</span
                  ><span class="en">CALENDAR</span>
                </h2>
                <div class="status_wrap">
                  <ul>
                    <li class="status_teikyu"><span>定休日</span></li>
                    <li class="status_rinji"><span>臨時休業日</span></li>
                    <li class="status_tokubetsu"><span>特別営業日</span></li>
                    <li class="status_news"><span>お知らせあり</span></li>
                  </ul>
                </div>
                <div class="deco_wrap01">
                  <img
                    src="@/assets/front_component/images/assets/kikyu03.png"
                    alt="気球"
                  />
                </div>
              </div>
            </div>

            <div
              id="inline-wrapcln"
              class="mfp-hide inline-wrap"
              data-group="1"
            >
              <div class="txt_wrap">
                <p class="top_header">
                  <time datetime="" id="information_date"></time> お知らせ
                </p>
                <p id="information_body"></p>
              </div>
              <button title="Close (Esc)" type="button" class="mfp-close">
                ×
              </button>
            </div>
            <div class="table_wrap">
              <div class="cln_wrap">
                <div class="inwrap">
                  <div class="cln_dsn_fixed">
                    <p class="cln_title">
                      <span class="en">{{ prevYear }}</span
                      >年<span class="en">{{ prevMonth }}</span
                      >月
                    </p>
                    <div id="prev_calendar" style="overflow: hidden"></div>
                    <img
                      src="@/assets/front_component/images/assets/cal04.png"
                      alt="角"
                      class="dog"
                    />
                  </div>
                </div>
                <div class="inwrap">
                  <div class="cln_dsn_fixed">
                    <p class="cln_title">
                      <span class="en">{{ thisYear }}</span
                      >年<span class="en">{{ thisMonth }}</span
                      >月
                    </p>
                    <div id="this_calendar" style="overflow: hidden"></div>
                    <img
                      src="@/assets/front_component/images/assets/cal04.png"
                      alt="角"
                      class="dog"
                    />
                  </div>
                </div>
                <div class="inwrap">
                  <div class="cln_dsn_fixed">
                    <p class="cln_title">
                      <span class="en">{{ nextYear }}</span
                      >年<span class="en">{{ nextMonth }}</span
                      >月
                    </p>
                    <div id="next_calendar" style="overflow: hidden"></div>
                    <img
                      src="@/assets/front_component/images/assets/cal04.png"
                      alt="角"
                      class="dog"
                    />
                  </div>
                </div>
              </div>
              <div class="content_in_wrap">
                <div class="arrow_wrap">
                  <div class="pager_prev arrow slick-arrow">
                    <div class="img_wrap">
                      <img
                        src="@/assets/front_component/images/assets/arrow03.png"
                        alt="戻る"
                      />
                    </div>
                    <span>前の月へ</span>
                  </div>
                  <div class="pager_next arrow slick-arrow">
                    <span>次の月へ</span>
                    <div class="img_wrap">
                      <img
                        src="@/assets/front_component/images/assets/arrow04.png"
                        alt="進む"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="deco_wrap01">
                <img
                  src="@/assets/front_component/images/assets/hana01.png"
                  alt="花"
                  class="hana01"
                />
                <img
                  src="@/assets/front_component/images/assets/hana02.png"
                  alt="花"
                  class="hana02"
                />
                <img
                  src="@/assets/front_component/images/assets/hana03.png"
                  alt="花"
                  class="hana03"
                />
              </div>
            </div>
          </div>
        </section>
        <div class="kusa_outline">
          <div class="kusa_area">
            <div class="deco_wrap01">
              <img
                src="@/assets/front_component/images/assets/kusa01.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap02">
              <img
                src="@/assets/front_component/images/assets/kusa02.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap03">
              <img
                src="@/assets/front_component/images/assets/kusa04.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap04">
              <img
                src="@/assets/front_component/images/assets/kusa03.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap05">
              <img
                src="@/assets/front_component/images/assets/kusa04.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap06">
              <img
                src="@/assets/front_component/images/assets/kusa03.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap07">
              <img
                src="@/assets/front_component/images/assets/kusa05.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap08">
              <img
                src="@/assets/front_component/images/assets/kusa04.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap09">
              <img
                src="@/assets/front_component/images/assets/kusa01.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap10">
              <img
                src="@/assets/front_component/images/assets/kusa07.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap11">
              <img
                src="@/assets/front_component/images/assets/kusa06.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap12">
              <img
                src="@/assets/front_component/images/assets/kusa01.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap13">
              <img
                src="@/assets/front_component/images/assets/kusa04.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap14">
              <img
                src="@/assets/front_component/images/assets/kusa03.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap15">
              <img
                src="@/assets/front_component/images/assets/kusa04.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap16">
              <img
                src="@/assets/front_component/images/assets/kusa03.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap17">
              <img
                src="@/assets/front_component/images/assets/kusa01.png"
                alt="草"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="menu_wrap">
      <div class="menu_icon">
        <img
          src="@/assets/front_component/images/assets/menu01.png"
          alt="メニュー"
        />
      </div>
      <div class="menu_list_wrap">
        <div class="menu_ttl">作成する</div>
        <ul class="menu_list">
          <li>
            <router-link
              :to="{
                name: 'Control messagecreate',
                params: { type: 'mysdgs' },
              }"
              >SDGsレポート</router-link
            >
          </li>
          <!-- <li>
            <router-link :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
              >お知らせ</router-link
            >
          </li> -->
          <li>
            <router-link
              :to="{
                name: 'Control messagecreate',
                params: { type: 'thanks' },
              }"
              >サンクス&ホメロンカード</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
              >創発カード</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
              >気づき日報</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'Control messagecreate',
                params: { type: 'photolog' },
              }"
              >フォトログ</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'Control messagecreate',
                params: { type: 'column' },
              }"
              >コラム</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import Api from "@/apis/Api";
import User from "@/apis/User";
import Groups from "@/apis/Groups";
import message from "@/apis/Message";
import event from "@/apis/Event";
import calendar from "@/apis/Calendar";
import dayjs from "dayjs";
import striptags from "striptags";

import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/jquery.magnific-popup.min.js";
import "@/assets/front_component/js/slick.min.js";
import "@/assets/front_component/js/fullcalendar/lib/moment.min.js";
import "@/assets/front_component/js/fullcalendar/fullcalendar3.1.0.min.js";
import "@/assets/front_component/js/fullcalendar/customfunctions.js";
import moment from "@/assets/front_component/js/fullcalendar/lib/moment.min.js";
import $ from "jquery";
import Notification from "@/apis/Notification";
import TopAnimation from './TopAnimation';

export default {
  data() {
    return {
      /*
      FanctionJs() {
        return FanctionJs
      },
      */
      profileImg: false,
      phone: "",
      errors: [],
      user: {
        email: null,
        password: null,
      },
      Validation: {
        userReult_email: "",
        userReult_password: "",
      },
      topics_messages: [],
      topics_messages_before: [],
      photo_messages: [],
      all_messages: [],
      news_messages: [],
      daily_messages: [],
      board_messages: [],
      event_messages: [],
      award_messages: [],
      thanks_messages: [],
      challenge_messages: [],
      idea_messages: [],
      column_messages: [],
      sdgs_messages: [],

      awardOneNum: "",
      awardTenNum: "",
      thanksNum: "",

      prevMonth: "",
      thisMonth: "",
      nextMonth: "",
      prevYear: "",
      thisYear: "",
      nextYear: "",
      notifiationNumForThanks: "",
      approvalNum: '',
      show_megurukun_animation: false,
    };
  },
  components: {
    TopAnimation
  },
  watch: {
    challenge_messages(val) {
      this.challenge_messages = val;
    },
    sdgs_messages(val) {
      this.sdgs_messages = val;
    },
    all_messages(val) {
      this.all_messages = val;
    },
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.phoneMatch();
    this.profileImgApi();
    this.notificationCount();

    //old commented to avoid loading sdgs on 07-11-22 by Mariyanandh
    //this.messageAll("type", "award,challenge,idea,sdgs", "published_at", 6);
    this.messageAll("type", "award,idea", "published_at", 6);

    this.messageNews("news");
    this.messageDaily("daily");
    this.messageBoard("board");
    //this.messageEvent('event');
    this.messageAward("award");
    this.messageThanks("thanks");
   // this.messageChallenge("challenge");
    this.messageIdea("idea");
    this.messageColumn("column");
   // this.messageSdgs("sdgs");
    this.messagePhoto("photolog", 18);
    this.awardCount("award", this.$User.id);
    this.thanksCount("thanks", this.$User.id);
    this.approvalCount(this.$User.id);
    this.messageTopics();
  },
  updated() {
    this.$nextTick(function () {
      var ua = navigator.userAgent;
      $(".board_ttl").each(function () {
        var $target = $(this);
        // オリジナルの文章を取得する
        var html = $target.html();
        // 対象の要素を、高さにautoを指定し非表示で複製する
        var $clone = $target.clone();
        $clone
          .css({
            display: "none",
            position: "absolute",
            overflow: "visible",
          })
          .width($target.width())
          .height("auto");
        // DOMを一旦追加
        $target.after($clone);
        // 指定した高さになるまで、1文字ずつ消去していく
        if (
          ua.indexOf("iPhone") > 0 ||
          (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
        ) {
          while (html.length > 0 && $clone.height() > 51) {
            html = html.substr(0, html.length - 1);
            $clone.html(html + "...");
          }
        } else {
          while (html.length > 0 && $clone.height() > 72) {
            html = html.substr(0, html.length - 1);
            $clone.html(html + "...");
          }
        }
        // 文章を入れ替えて、複製した要素を削除する
        $target.html($clone.html());
        $clone.remove();
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
    });
  },
  mounted() {
    document.body.className = "top_page";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      //タブ切り替え
      $(function () {
        // タブメニューをクリックしたとき
        $(".tab_list > li").on("click", function () {
          // タブメニューとタブコンテンツのクラス「active」を削除
          $(".tab_list > li, .link_list, .link_list+.more").removeClass(
            "active"
          );
          // タブメニューのクラスを取得し、変数「tabClass」に格納（例：sky）
          var tabClass = $(this).attr("class");
          // クリックしたタブメニューにクラス「active」を付与
          $(this).addClass("active");
          // それぞれのタブコンテンツに対して
          $(".link_list").each(function () {
            // 変数「tabClass」と、同じクラスがついたタブコンテンツに
            if ($(this).attr("class").indexOf(tabClass) != -1) {
              // クラス「active」を付与し、フェードインしながら表示
              $(this).addClass("active").fadeIn();
              $(this).next(".more").addClass("active").fadeIn();
              // それ以外のタブコンテンツは
            } else {
              // 隠す
              $(this).hide();
            }
          });
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
        setTimeout(function () {
          $(".mascot_wrap").addClass("show_eye");
        }, 900);
        setTimeout(function () {
          $(".mascot_wrap").addClass("show_all");
        }, 1900);
        setTimeout(function () {
          $(".mascot_wrap").addClass("show_hand");
        }, 2400);
        setTimeout(function () {
          $(".mascot_wrap").addClass("show_end");
        }, 2500);
      });
      $(function () {
        var ua = navigator.userAgent;
        if (
          ua.indexOf("iPhone") > 0 ||
          (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
        ) {
          $(document).ready(function () {
            $(".cln_wrap").slick({
              //autoplay: true,
              infinite: false,
              speed: 300,
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              prevArrow: $(".pager_prev"),
              nextArrow: $(".pager_next"),
            });
          });
        } else {
          $(document).ready(function () {
            $(".cln_wrap").slick({
              infinite: false,
              speed: 300,
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
              prevArrow: $(".pager_prev"),
              nextArrow: $(".pager_next"),
            });
          });
        }
      });
    });
    //import ("@/assets/front_component/js/functions.js");
  },
  methods: {
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    phoneMatch: function () {
      var ua = navigator.userAgent;
      if (
        ua.indexOf("iPhone") > 0 ||
        (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
      ) {
        this.phone = true;
        this.calcMonth();
      } else {
        this.phone = false;
        this.calcMonth();
      }
    },
    approvalCount(user) {
      message
        .listNumCountApproval(user)
        .then((response) => {
          if (response != null) {
            this.approvalNum = response.data.data.length;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    formatDate_imageNum: (dateStr) => dayjs(dateStr).format("ss"),

    calcMonth() {
      const nowLocal = new Date();
      //今月
      this.thisYear = nowLocal.getFullYear();
      this.thisMonth = nowLocal.getMonth() + 1;
      //前月
      if (this.thisMonth == 1) {
        this.prevYear = this.thisYear - 1;
        this.prevMonth = 12;
      } else {
        this.prevYear = this.thisYear;
        this.prevMonth = this.thisMonth - 1;
      }
      //来月
      if (this.thisMonth == 12) {
        this.nextYear = this.thisYear + 1;
        this.nextMonth = 1;
      } else {
        this.nextYear = this.thisYear;
        this.nextMonth = this.thisMonth + 1;
      }
      if (this.prevMonth > 9) {
        var prevCalendar = this.prevYear + "-" + this.prevMonth;
      } else {
        prevCalendar = this.prevYear + "-0" + this.prevMonth;
      }
      if (this.thisMonth > 9) {
        var thisCalendar = this.thisYear + "-" + this.thisMonth;
      } else {
        thisCalendar = this.thisYear + "-0" + this.thisMonth;
      }
      if (this.nextMonth > 9) {
        var nextCalendar = this.nextYear + "-" + this.nextMonth;
      } else {
        nextCalendar = this.nextYear + "-0" + this.nextMonth;
      }

      $(function () {
        $("#prev_calendar").fullCalendar({
          height: 600,
          buttonText: false,
          header: false,
          locale: "ja",
          firstDay: 0,
          timeFormat: "HH:mm", // 時間表示フォーマット
          timezone: "Asia/Tokyo", // タイムゾーン設定
          eventLimit: true, // イベント増えた時にリンクボタン表示
          editable: false, // ドラッグアンドドロップ禁止
          slotEventOverlap: true, // イベントの見た目を重ねて表示
          selectable: true, // カレンダー空白部分のドラッグ可能
          selectMinDistance: 1, //
          dayNames: [
            "日曜日",
            "月曜日",
            "火曜日",
            "水曜日",
            "木曜日",
            "金曜日",
            "土曜日",
          ],
          dayNamesShort: ["日", "月", "火", "水", "木", "金", "土"],
          monthNames: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ],
          titleFormat: "YYYY/M",
          //defaultDate: '2021-11',
          defaultDate: prevCalendar,
          fixedWeekCount: false,
          dayClick: function (date, jsEvent, view) {
            //カレンダー空白部分クリック時のイベント
            dayClickRuleProc(date, jsEvent, view, "#calendar"); //クリック対象セルがルールを持っている場合の処理
            modalMod(date); //モーダル内inputへのデータ渡し・書き出し
            modalToggle("#editModal"); //モーダル表示非表示切替
          },
          eventRender: function (events, element) {
            var jdg;
            if (events.dow) {
              //繰り返し系スケジュールの場合
              jdg = ruleJudge(events, element);
            }
            if (jdg == false) {
              $(this).removeClass(); //.repeatや.r_idなどのクラスを省く
              return false;
            }
            backgroundrule(events, element);
          },
          // events: this.allCalendarSchedulsLoad,
          events: function (start, end, timezone, callback) {
            calendar
              .schedulesRule()
              .then(async (doc) => {
                let events = [];
                if (!!doc.data.data) {
                  await $.map(doc.data.data, function (r) {
                    //console.log(r);
                    if (
                      r.type == "お知らせあり" ||
                      r.type == "特別営業日" ||
                      r.type == "定休日" ||
                      r.type == "臨時休業日"
                    ) {
                      let pushArray = {
                        id: r.id,
                        title: r.type,
                        body: r.content,
                      };

                      if (r.day_of_the_week != null) {
                        pushArray.dow = r.day_of_the_week;
                        pushArray.period_type = r.period_type;
                        // pushArray.ranges = [
                        //   { start: "1970-01-01", end: "2500-12-31" },
                        // ];
                        pushArray.ranges = r.ranges;
                        //pushArray.rendering = "background";
                        //pushArray.textColor = "#fff";
                        //pushArray.className = "repeat rid_39";
                      } else {
                        if (r.start_date != "") {
                          pushArray.start = r.start_date;
                        }
                        if (r.end_date != "") {
                          pushArray.end = r.end_date;
                        }
                      }

                      events.push(pushArray);
                    }
                  });

                  callback(events);
                }
              })
              .catch((error) => {
                console.log("エラー");
              })
              .finally(() => {});
          },
          eventClick: function (calEvent, jsEvent, view) {
            eventClick(calEvent);
          },
        });
        $("#this_calendar").fullCalendar({
          height: 600,
          buttonText: false,
          header: false,
          locale: "ja",
          firstDay: 0,
          timeFormat: "HH:mm", // 時間表示フォーマット
          timezone: "Asia/Tokyo", // タイムゾーン設定
          eventLimit: true, // イベント増えた時にリンクボタン表示
          editable: false, // ドラッグアンドドロップ禁止
          slotEventOverlap: true, // イベントの見た目を重ねて表示
          selectable: true, // カレンダー空白部分のドラッグ可能
          selectMinDistance: 1, //
          dayNames: [
            "日曜日",
            "月曜日",
            "火曜日",
            "水曜日",
            "木曜日",
            "金曜日",
            "土曜日",
          ],
          dayNamesShort: ["日", "月", "火", "水", "木", "金", "土"],
          monthNames: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ],
          titleFormat: "YYYY/M",
          defaultDate: thisCalendar,
          fixedWeekCount: false,
          dayClick: function (date, jsEvent, view) {
            //カレンダー空白部分クリック時のイベント
            dayClickRuleProc(date, jsEvent, view, "#calendar"); //クリック対象セルがルールを持っている場合の処理
            modalMod(date); //モーダル内inputへのデータ渡し・書き出し
            modalToggle("#editModal"); //モーダル表示非表示切替
          },
          eventRender: function (events, element) {
            var jdg;
            if (events.dow) {
              //繰り返し系スケジュールの場合
              jdg = ruleJudge(events, element);
            }
            if (jdg == false) {
              $(this).removeClass(); //.repeatや.r_idなどのクラスを省く
              return false;
            }
            backgroundrule(events, element);
          },
          // events: this.allCalendarSchedulsLoad,
          events: function (start, end, timezone, callback) {
            calendar
              .schedulesRule()
              .then(async (doc) => {
                let events = [];
                if (!!doc.data.data) {
                  await $.map(doc.data.data, function (r) {
                    if (
                      r.type == "お知らせあり" ||
                      r.type == "特別営業日" ||
                      r.type == "定休日" ||
                      r.type == "臨時休業日"
                    ) {
                      let pushArray = {
                        id: r.id,
                        title: r.type,
                        body: r.content,
                      };

                      if (r.day_of_the_week != null) {
                        pushArray.dow = r.day_of_the_week;
                        pushArray.period_type = r.period_type;
                        // pushArray.ranges = [
                        //   { start: "1970-01-01", end: "2500-12-31" },
                        // ];
                        pushArray.ranges = r.ranges;
                        //pushArray.rendering = "background";
                        //pushArray.textColor = "#fff";
                        //pushArray.className = "repeat rid_39";
                      } else {
                        if (r.start_date != "") {
                          pushArray.start = r.start_date;
                        }
                        if (r.end_date != "") {
                          pushArray.end = r.end_date;
                        }
                      }

                      events.push(pushArray);
                    }
                  });

                  callback(events);
                }
              })
              .catch((error) => {
                console.log("エラー");
              })
              .finally(() => {});
          },
          eventClick: function (calEvent, jsEvent, view) {
            eventClick(calEvent);
          },
        });
        $("#next_calendar").fullCalendar({
          height: 600,
          buttonText: false,
          header: false,
          locale: "ja",
          firstDay: 0,
          timeFormat: "HH:mm", // 時間表示フォーマット
          timezone: "Asia/Tokyo", // タイムゾーン設定
          eventLimit: true, // イベント増えた時にリンクボタン表示
          editable: false, // ドラッグアンドドロップ禁止
          slotEventOverlap: true, // イベントの見た目を重ねて表示
          selectable: true, // カレンダー空白部分のドラッグ可能
          selectMinDistance: 1, //
          dayNames: [
            "日曜日",
            "月曜日",
            "火曜日",
            "水曜日",
            "木曜日",
            "金曜日",
            "土曜日",
          ],
          dayNamesShort: ["日", "月", "火", "水", "木", "金", "土"],
          monthNames: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ],
          titleFormat: "YYYY/M",
          defaultDate: nextCalendar,
          fixedWeekCount: false,
          dayClick: function (date, jsEvent, view) {
            //カレンダー空白部分クリック時のイベント
            dayClickRuleProc(date, jsEvent, view, "#calendar"); //クリック対象セルがルールを持っている場合の処理
            modalMod(date); //モーダル内inputへのデータ渡し・書き出し
            modalToggle("#editModal"); //モーダル表示非表示切替
          },
          eventRender: function (events, element) {
            var jdg;
            if (events.dow) {
              //繰り返し系スケジュールの場合
              jdg = ruleJudge(events, element);
            }
            if (jdg == false) {
              $(this).removeClass(); //.repeatや.r_idなどのクラスを省く
              return false;
            }
            backgroundrule(events, element);
          },
          // events: this.allCalendarSchedulsLoad,
          events: function (start, end, timezone, callback) {
            calendar
              .schedulesRule()
              .then(async (doc) => {
                let events = [];
                if (!!doc.data.data) {
                  await $.map(doc.data.data, function (r) {
                    //console.log(r);
                    if (
                      r.type == "お知らせあり" ||
                      r.type == "特別営業日" ||
                      r.type == "定休日" ||
                      r.type == "臨時休業日"
                    ) {
                      let pushArray = {
                        id: r.id,
                        title: r.type,
                        body: r.content,
                      };

                      if (r.day_of_the_week != null) {
                        pushArray.dow = r.day_of_the_week;
                        pushArray.period_type = r.period_type;
                        // pushArray.ranges = [
                        //   { start: "1970-01-01", end: "2500-12-31" },
                        // ];
                        pushArray.ranges = r.ranges;
                        //pushArray.rendering = "background";
                        //pushArray.textColor = "#fff";
                        //pushArray.className = "repeat rid_39";
                      } else {
                        if (r.start_date != "") {
                          pushArray.start = r.start_date;
                        }
                        if (r.end_date != "") {
                          pushArray.end = r.end_date;
                        }
                      }

                      events.push(pushArray);
                    }
                  });

                  callback(events);
                }
              })
              .catch((error) => {
                console.log("エラー");
                //console.log(error);
              })
              .finally(() => {});
          },
          eventClick: function (calEvent, jsEvent, view) {
            eventClick(calEvent);
          },
        });

        /*-----------------------------------------------------
        クリック対象セルがルールを持っている場合の処理
        --------------------------------------------------------*/
        function dayClickRuleProc(date, jsEvent, view, target) {
          //▼クリックされた日のイベントを扱う
          $(target).fullCalendar("clientEvents", function (e) {
            if (
              moment(date).format("YYYY-MM-DD") ===
              moment(e.start).format("YYYY-MM-DD")
            ) {
              var target_repeat = e.className;
              var rule_id;
              if (target_repeat.indexOf("repeat") >= 0) {
                //class内にrepeat：繰り返しルールが存在するか
                rule_id = target_repeat[1].replace("rid_", "");
                $("#leave_rule")
                  .parents(".form_outwrap")
                  .css("display", "block");
                $("#leave_rule").val(rule_id);
              } else {
                $("#leave_rule")
                  .parents(".form_outwrap")
                  .css("display", "none");
              }
            }
          });
        }
        /*-----------------------------------------------------
        モーダル内inputへのデータ渡し・書き出し
        --------------------------------------------------------*/
        function modalMod(event, eTitle) {
          //クリック対象セルがルールを持っている場合の処理
          $(".title").each(function () {
            if ($(this).val() == eTitle) {
              $(this).prop("checked", "true");
            }
          });
          $("#start_time").val(moment(event._d).format("YYYY-MM-DD"));
          $("#start_time_in").text(moment(event._d).format("YYYY-MM-DD"));
          $("#finish_time").val(moment(event.end).format("YYYY-MM-DD"));
          $("#title").val(event.title);
          $("#body").val(event.body);
          $("#event_id").val(event.id);
        }
        //▼表示-＞非表示処理
        function modalToggle(target) {
          $("#txtbody").hide();
          $(target).addClass("open");
          if ($(".news_chk").prop("checked")) {
            $("#txtbody").show();
          }
        }
        /*-----------------------------------------------------
          繰り返し（schedule_rule）の場合の書き出し判定
        --------------------------------------------------------*/
        function ruleJudge(events, element) {
          //書き出す日付
          var thisDay = moment(events.start._d);
          var eventPeriodType = events.period_type;

          /*繰り返し週(週数指定)
          -------------------------*/
          if (events.period_type.match(/th_week/)) {
            var week = events.period_type.replace("th_week", ""); //データの繰り返し設定週を取得
            var n = Math.floor((thisDay.date() - thisDay.day() + 12) / 7); //現在日付が第何週かを計算

            if (week != n) {
              //対応する週数でない場合は書き出し中止返り値としてfalse
              return false;
            }
          }

          /*繰り返し週(第〇曜日指定)
          -------------------------*/
          if (events.period_type.match(/th_dayof/)) {
            week = events.period_type.replace("th_dayof", ""); //データの繰り返し設定週を取得
            n = Math.floor((events.start._d.getDate() - 1) / 7) + 1; //現在日付が第何曜日かを計算

            if (week != n) {
              //対応する週数でない場合は書き出し中止返り値としてfalse
              return false;
            }
          }

          /*期間（除外日から）
          -------------------------*/
          var $flag = false;
          for (var i = 0, len = events.ranges.length; i < len; ++i) {
            if (
              thisDay.isBetween(events.ranges[i].start, events.ranges[i].end)
            ) {
              $flag = true;
            }
          }
          if (!$flag) {
            //書き出し中止返り値としてfalse
            return false;
          }
        }
        /*-----------------------------------------------------
        イベントによるバックグラウンド判定
        --------------------------------------------------------*/
        function backgroundrule(events, element) {
          if (events.title == "定休日") {
            var dataToFind = moment(events.start).format("YYYY-MM-DD");
            $("td[data-date='" + dataToFind + "']").addClass("status_teikyu");
          }
          if (events.title == "臨時休業日") {
            dataToFind = moment(events.start).format("YYYY-MM-DD");
            $("td[data-date='" + dataToFind + "']").addClass("status_rinji");
          }
          if (events.title == "特別営業日") {
            dataToFind = moment(events.start).format("YYYY-MM-DD");
            $("td[data-date='" + dataToFind + "']").addClass(
              "status_tokubetsu"
            );
          }
          if (events.title == "お知らせあり") {
            var dateNum = moment(events.start).format("D");
            dataToFind = moment(events.start).format("YYYY-MM-DD");
            $("td[data-date='" + dataToFind + "']").addClass("status_oshirase");
            $("td[data-date='" + dataToFind + "']").html(
              '<a href="#inline-wrapcln" class="fc-day-number pop-modal_cal">' +
                dateNum +
                "</a>"
            );
            //console.log(dateNum);
            element.addClass("status_news");
            element.addClass("pop-modal");
            element.attr("href", "#inline-wrapcln");
            element.find(".fc-title").text("");
          }
        }
        $(document).on("click", ".pop-modal_cal", function (e) {
          // prevent default action
          e.preventDefault();
          $(this)
            .magnificPopup({
              type: "inline",
              preloader: false,
              closeOnContentClick: true,
              closeBtnInside: true,
              removalDelay: 300,
              gallery: {
                //enabled: true,
              },
              callbacks: {
                beforeOpen: function () {
                  this.st.image.markup = this.st.image.markup.replace(
                    "mfp-figure",
                    "mfp-figure mfp-with-anim"
                  );
                  this.st.mainClass = this.st.el.attr("data-effect");
                },
              },
            })
            .magnificPopup("open");
        });
        /*-----------------------------------------------------
        フロントトップ：カレンダーイベントクリック
        --------------------------------------------------------*/
        function eventClick(calEvent) {
          var beforeDate = moment(calEvent.start._d, "YYYY-MM-DD");
          $("#information_date").html(beforeDate.format("YYYY年MM月DD日"));
          $("#information_body").html(calEvent.body);
        }
      });
    },
    thumNum(num) {
      var imageNum = "";
      imageNum = this.formatDate_imageNum(num);
      var str = String(imageNum);
      var footSliceTen = str.slice(0, -1);
      var numTen = Number(footSliceTen);
      imageNum = Number(numTen);
      return imageNum;
    },
    messagePhoto(type, num) {
      this.loader = true;
      message
        .listNumMessage02(type, num)
        .then(
          (response) => {
            if (response != null) {
              this.photo_messages = response.data.data;
              this.photo_messages.forEach((message_single, i) => {
                let removeNbsp = message_single.content.replace(/&nbsp;/g, "");
                message_single.content = striptags(removeNbsp);
              });

              $(function () {
                $(".pop-modal").magnificPopup({
                  type: "inline",
                  preloader: false,
                  closeOnContentClick: false,
                  closeBtnInside: true,
                  removalDelay: 300,
                  gallery: {
                    //ギャラリー表示にする
                    enabled: false,
                  },
                });
              });
            }
          },
          (error) => {
            //console.log(error);
            console.log(error.response.data.errors);
            //this.$router.push({ name: 'Front Error' });
          }
        )
        .catch((error) => {
          //console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getImage(id, i) {
      message
        .showfile(id, "key", "photolog")
        .then(
          (response) => {
            if (response != "") {
              let fileApiPass =
                "/api/v1/file/" + response.data.data[0].storage_file_name;
              let src =
                "data:image/jpeg;base64," + response.data.data[0].base64;
              this.photo_messages[i].imgpath = src;
            }
          },
          (error) => {
            //console.log(error);
          }
        )
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {});
    },
    messageTopics() {
      this.loader = true;

      message
        .topic_index()
        .then((response) => {
          if (response != null) {
            
            this.topics_messages = response.data.data;

            this.topics_messages = this.topics_messages.filter((e) => {              
              if (e.type == "column" ) {
                console.log(e.from_user.role );
                if(e.from_user.role != "取締役会長" && e.from_user.role != "代表取締役社長"){
                  console.log(e);
                  return false;
                }
              }
              return e;
            });


            $(function () {
              var topisc_num = $(".topisc_item").length;
              if (topisc_num == 3) {
                var show_num = 1;
              } else if (topisc_num > 3) {
                show_num = 3;
              }
              if (topisc_num > 2) {
                $(".topics_slide").slick({
                  autoplay: true,
                  infinite: true,
                  speed: 300,
                  slidesToShow: show_num,
                  slidesToScroll: 1,
                  arrows: true,
                  prevArrow: $(".slide_prev"),
                  nextArrow: $(".slide_next"),
                  dots: true,
                  appendDots: $(".topics_btn_wrap"),
                  dotsClass: "dots-list",
                  variableWidth: true,
                  centerMode: true,
                  pauseOnHover: true,
                  responsive: [
                    {
                      breakpoint: 760,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: false,
                      },
                    },
                  ],
                });
              } else {
                $(".topics_btn_wrap").remove();
              }
              $(".topics_slide").css("opacity", "1");
            });
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageAll(field, value, desc, num) {
     
      this.loader = true;
      message.tag_list("他部署報告").then((response) => {
        if (response.data.data.length > 0) {
          var nottag = response.data.data[0].id;
          message
            .listMultiple(field, value, desc, num, nottag)
            .then((response) => {
              if (response != null) {
                console.log(response.data.data);
                this.all_messages = response.data.data;

                for (var key in this.all_messages) {
                  if (this.all_messages[key].type == "event") {
                    this.all_messages[key].typejp = "イベント";
                  } else if (this.all_messages[key].type == "daily") {
                    this.all_messages[key].typejp = "気づき日報";
                  } else if (this.all_messages[key].type == "award") {
                    this.all_messages[key].typejp = "表彰";
                  } else if (this.all_messages[key].type == "thanks") {
                    this.all_messages[key].typejp = "サンクスカード";
                  } else if (this.all_messages[key].type == "challenge") {
                    this.all_messages[key].typejp = "チャレンジシート";
                  } else if (this.all_messages[key].type == "idea") {
                    this.all_messages[key].typejp = "創発";
                  } else if (this.all_messages[key].type == "column") {
                    this.all_messages[key].typejp = "コラム";
                  } else if (this.all_messages[key].type == "sdgs") {
                    this.all_messages[key].typejp = "SDGs";
                  } else {
                    this.all_messages[key].typejp = "その他";
                  }
                  this.all_messages[key].content = striptags(
                    this.all_messages[key].content
                  );
                  this.all_messages[key].content = this.all_messages[
                    key
                  ].content.replace(/&nbsp;/g, " ");
                }
                this.messageEvent(6);
              }
              this.show_megurukun_animation = true;
            })
            .catch((error) => {
              this.show_megurukun_animation = true;
              //console.log(error);
            })
            .finally(() => {
              this.loader = false;
              if (this.show_megurukun_animation) {
                  console.log('show_megurukun_animation',this.show_megurukun_animation);
              } else {
                this.show_megurukun_animation = true;
              }
            });
        } else {
          message
            .listMultiple(field, value, desc, num, null)
            .then((response) => {
              if (response != null) {
                this.all_messages = response.data.data;
                console.log(this.all_messages);
                for (var key in this.all_messages) {
                  if (this.all_messages[key].type == "event") {
                    this.all_messages[key].typejp = "イベント";
                  } else if (this.all_messages[key].type == "daily") {
                    this.all_messages[key].typejp = "気づき日報";
                  } else if (this.all_messages[key].type == "award") {
                    this.all_messages[key].typejp = "表彰";
                  } else if (this.all_messages[key].type == "thanks") {
                    this.all_messages[key].typejp = "サンクスカード";
                  } else if (this.all_messages[key].type == "challenge") {
                    this.all_messages[key].typejp = "チャレンジシート";
                  } else if (this.all_messages[key].type == "idea") {
                    this.all_messages[key].typejp = "創発";
                  } else if (this.all_messages[key].type == "column") {
                    this.all_messages[key].typejp = "コラム";
                  } else if (this.all_messages[key].type == "sdgs") {
                    this.all_messages[key].typejp = "SDGs";
                  } else {
                    this.all_messages[key].typejp = "その他";
                  }
                  this.all_messages[key].content = striptags(
                    this.all_messages[key].content
                  );
                  this.all_messages[key].content = this.all_messages[
                    key
                  ].content.replace(/&nbsp;/g, " ");
                }
                this.messageEvent(6);
              }
              this.show_megurukun_animation = true;
            })
            .catch((error) => {
              this.show_megurukun_animation = true;
              //console.log(error);
            })
            .finally(() => {
              this.loader = false;
              if (this.show_megurukun_animation) {
              } else {
                this.show_megurukun_animation = true;
              }
            });
        }
      });
    },
    messageNews(type) {
      this.loader = true;

      message
        .listNumMessage(type, 10)
        .then((response) => {
          if (response != null) {
            this.news_messages = response.data.data;
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageDaily(type) {
      let groupId = null;
      if (this.$User.group) {
        if (this.$User.group.parent_id == null) {
          groupId = this.$User.group.id;
        } else {
          groupId = this.$User.group.parent_id;
        }
      }

      this.loader = true;
      var tags = [];
      message
        .tag_list(["改善", "コミュニケーション", "お客様の声"])
        .then((response) => {
          if (response.data.data.length > 0) {
            response.data.data.forEach((element) => {
              tags.push(element.id);
            });
            message
              .listNumMessage(type, 10, null, groupId, null, null, tags)
              .then((response) => {
                if (response != null) {
                  this.daily_messages = response.data.data;
                  this.daily_messages.forEach((message) => {
                    message.typejp = "気づき日報";
                    message.content = striptags(message.content);
                    message.content = message.content.replace(/&nbsp;/g, " ");
                  });
                }
              })
              .catch((error) => {
                //console.log(error);
              })
              .finally(() => {
                this.loader = false;
              });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    htmlText(msg) {
      return msg.replace(/\r?\n/g, "<br>");
    },
    replyList(id, i) {
      this.loader = true;
      message
        .listChild(id, "board_res")
        .then(
          (response) => {
            if (response != null) {
              this.board_messages[i].child_num = response.data.data.length;
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          //console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    goodList(id, i) {
      this.loader = true;
      message
        .listChild(id, "board_good")
        .then(
          (response) => {
            if (response != null) {
              this.board_messages[i].good_num = response.data.data.length;
            }
          },
          (error) => {
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          //console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageBoard(type) {
      this.loader = true;

      message
        .listNumMessage(type, 10)
        .then((response) => {
          if (response != null) {
            this.board_messages = response.data.data;
            this.board_messages.forEach((ob, i) => {
              this.replyList(ob.id, i);
              this.goodList(ob.id, i);
            });
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageEvent(num) {
      this.loader = true;

      event
        .listNum(num)
        .then((response) => {
          if (response != null) {
            this.event_messages = response.data.data;

            const all_array = this.all_messages;
            const daily_array = this.daily_messages;
            const event_array = this.event_messages;

            for (let i = 0; i < event_array.length; i++) {
              all_array.push(event_array[i]);
            }
            for (let i = 0; i < daily_array.length; i++) {
              all_array.push(daily_array[i]);
            }

            let result = all_array.sort(function (a, b) {
              return a.published_at > b.published_at ? -1 : 1; //オブジェクトの降順ソート
            });

            this.all_messages = result;

            this.all_messages.forEach((message) => {
              if (message.type == "challenge" || message.type == "sdgs") {
                User.show(message.from_id).then((response) => {
                  if (response != null) {
                    message.user = response.data.data;
                  }
                });

                message.option = "";
                if (message.content.indexOf("{") != -1) {
                  var contentSplit = JSON.parse(message.content);
                  var TermoOption = contentSplit;
                  message.option = TermoOption["option"];
                } else {
                  message.option = null;
                }

                message.content = striptags(message.content);
                message.content = message.content.replace(/&nbsp;/g, " ");
              }
            });
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageAward(type) {
      this.loader = true;

      message
        .listNumMessage(type, 10)
        .then((response) => {
          if (response != null) {
            this.award_messages = response.data.data;
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageThanks(type) {
      this.loader = true;

      message
        .listNumMessage(type, 10)
        .then((response) => {
          if (response != null) {
            this.thanks_messages = response.data.data;
            this.thanks_messages.forEach((message) => {
              message.content = striptags(message.content);
              message.content = message.content.replace(/&nbsp;/g, " ");
            });
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageChallenge(type) {
      this.loader = true;

      message
        .listNumMessage(type, 10)
        .then((response) => {
          if (response != null) {
            this.challenge_messages = response.data.data;
            this.challenge_messages.forEach((message) => {
              User.show(message.from_id).then((response) => {
                if (response != null) {
                  message.user = response.data.data;
                }
              });

              message.option = "";
              if (message.content.indexOf("{") != -1) {
                var contentSplit = JSON.parse(message.content);
                var TermoOption = contentSplit;
                message.option = TermoOption["option"];
              } else {
                message.option = null;
              }

              message.content = striptags(message.content);
              message.content = message.content.replace(/&nbsp;/g, " ");
            });
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageIdea(type) {
      this.loader = true;

      message
        .listNumMessage(type, 10)
        .then((response) => {
          if (response != null) {
            this.idea_messages = response.data.data;
            this.idea_messages.forEach((message) => {
              message.content = striptags(message.content);
              message.content = message.content.replace(/&nbsp;/g, " ");
            });
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageColumn(type) {
      this.loader = true;

      message
        .listNumMessage02(type, 5)
        .then((response) => {
          if (response != null) {
            this.column_messages = response.data.data;
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageSdgs(type) {
      this.loader = true;

      message
        .listNumMessage(type, 30)
        .then((response) => {
          if (response != null) {
            this.sdgs_messages = response.data.data;
            this.sdgs_messages.forEach((message) => {
              User.show(message.from_id).then((response) => {
                if (response != null) {
                  message.user = response.data.data;
                }
              });

              message.option = "";
              if (message.content.indexOf("{") != -1) {
                var contentSplit = JSON.parse(message.content);
                var TermoOption = contentSplit;
                message.option = TermoOption["option"];
              } else {
                message.option = null;
              }

              message.content = striptags(message.content);
              message.content = message.content.replace(/&nbsp;/g, " ");
            });
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    top() {
      // showUser
      this.loader = true;
      User.top()
        .then((response) => {
          if (response != null) {
            console.log(response);
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    awardCount(type, user) {
      this.loader = true;

      message
        .listNumCount(type, user)
        .then((response) => {
          if (response != null) {
            var str = String(response.data.data.length);

            if (str.length > 1) {
              var footSliceOne = str.slice(-1);
              var numOne = Number(footSliceOne);
              this.awardOneNum = numOne;
              var footSliceTen = str.slice(0, -1);
              var numTen = Number(footSliceTen);
              this.awardTenNum = numTen;
            } else {
              this.awardOneNum = response.data.data.length;
            }
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    thanksCount(type, user) {
      this.loader = true;

      message
        .listNumCount(type, user)
        .then((response) => {
          if (response != null) {
            this.thanksNum = response.data.data.length;
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    notificationCount() {
      Notification.thanksNotificationCount("thanks")
        .then(
          (response) => {
            if (response != null) {
              this.notifiationNumForThanks = response.data.data;
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    viewContent(content) {
      if (content.indexOf("{") != -1) {
        let contentSplit = JSON.parse(content);
        return contentSplit.content;
      } else {
        return content;
      }
    },
    showYear(year) {
      if (typeof year == "number") {
        year = year.toString();
      }

      let setYear = "";
      switch (year) {
        case "2022":
          setYear = "49期 (2022)";
          break;
        case "2023":
          setYear = "50期 (2023)";
          break;
        case "2024":
          setYear = "51期 (2024)";
          break;
        case "2025":
          setYear = "52期 (2025)";
          break;
        case "2026":
          setYear = "53期 (2026)";
          break;
        default:
          setYear = year;
          break;
      }

      return setYear;
    },
  },
};
</script>
<style>
.column-role {
  font-size: 0.8rem !important;
  margin-left: 10px;
}
</style>